
import { Component, Vue } from 'vue-property-decorator';

import FormInput from '@/components/common/FormInput.vue';
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';

import User from '@/store/modules/User';
import ApiError from '@/services/api/models/ApiError';

import { Validation, validationMixin } from 'vuelidate';
import { BvModalEvent } from 'bootstrap-vue';

@Component({
  components: {
    FormInput,
    ErrorDisplay
  },
  mixins: [validationMixin]
})
export default class DeleteAccount extends Vue {
  public form = {
    name: ''
  };
  public submitting = false;
  public error: string | null = null;

  public get userName(): string | null {
    return User._currentUser?.name ?? null;
  }

  public async onSubmit(event: BvModalEvent): Promise<void> {
    event.preventDefault();
    if (
      User._currentUser == null ||
      this.form.name != this.userName ||
      this.$route.params.userId == null ||
      this.$route.query.expires == null ||
      this.$route.query.signature == null
    ) {
      this.error = 'Failed to delete account: Invalid signature';
      return;
    }

    this.error = null;
    this.submitting = true;

    User.deleteAccount({
      userId: parseInt(this.$route.params.userId),
      params: {
        expires: this.$route.query.expires as string,
        signature: this.$route.query.signature as string
      }
    })
      .then(response => {
        if (!response) {
          this.submitting = false;
          throw {
            status: 500,
            errorMessage: 'Unknown error'
          } as ApiError;
        }

        this.error = null;
        this.submitting = false;

        this.$bvModal.show('delete-account-success');
      })
      .catch((error: ApiError) => {
        this.error = `Failed to delete account: ${error.errorMessage}`;
        this.submitting = false;
      });
  }

  public async redirectToLogin(): Promise<void> {
    await User.logout();
    await this.$router.push('/auth/login');
  }
}
